import React, { useState } from "react";

import "./styles.css";

import Content from "./components2/Content";
import APIOffline from "./components2/APIOffline";
import Demo from "./components2/Demo";
import Vaccinaties from "./components2/Vaccinaties";
import blue from '@material-ui/core/colors/blue';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { I18nextProvider } from "react-i18next";

import { nlNL } from '@material-ui/core/locale';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#fb882e',
            contrastText: '#fff',
        },
        secondary: {
            main: blue[700],
        },
    },
}, nlNL);

// const test = 'https://reisstudentarts.nl/api/';




export default function ReisFormApp() {

    const [APIWorking, setAPIWorking] = useState(true);
    const checkAPI =() => {
        if (APIWorking) {
            fetch(`https://reisstudentarts.nl/api/`)
                .then((response) => {
                    console.log(`Response status: ${response.status}`)
                    if (response.status !== 404){
                     setAPIWorking(false)  ;
                    }
                }).catch(function() {
                    setAPIWorking(false)  ;
                });
        }
    }
    checkAPI();
    return (
            <ThemeProvider theme={theme} >
                    {APIWorking ? <Content/> : <APIOffline/>}
                {/*<Vaccinaties />*/}
            </ThemeProvider>

    );
}
