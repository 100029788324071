import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import AddressForm from './AddressForm';
import ReisForm from './ReisForm';
import Review from './Review';
import logo from "./../images/logo.png";
import flag_eng from "./../images/flag_eng.png"
import flag_nl from "./../images/flag_nl.png"
import Success from "./Success";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from '@material-ui/core/styles';
import { useTranslation, withTranslation, Trans } from "react-i18next";



function Copyright() {
  return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://reisstudentarts/">
          ReisStudentArts
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  inline_block:{
    display: 'inline-block'
  },
  flag:{
    height: 20,
    width: 40,
    float:'right',
    cursor: 'pointer',
    margin: 2,
  },
  to_right:{
    marginLeft: 'auto',
    marginRight: '20px'
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  logo: {
    maxWidth: 200,
  },


}));

const steps = ['Persoonsgegevens', 'Reisgegevens', 'Bevestigen'];


export default function Content() {

  const theme = useTheme();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <AddressForm Next={handleNext} classes={classes}/>;
      case 1:
        return <ReisForm Next={handleNext} Back={handleBack} classes={classes} />;
      case 2: 
        return <Review Next={handleNext} Back={handleBack} classes={classes} />;
      case 3:
        return <Success />;
      default:
        throw new Error('Unknown step');
    }
  }

  const small_screen = useMediaQuery(theme.breakpoints.up('md'));
  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
  }

  return (
      <React.Fragment>
        <CssBaseline />
        <AppBar position="absolute" color="default" className={classes.appBar}>
          <Toolbar>
            <a href={'/'}><img src={logo} alt="logo" className={classes.logo}/></a>
            <div className={classes.to_right}>
            <img src={flag_eng} onClick={() => changeLanguage('en') } className={classes.flag} />
            <img src={flag_nl} onClick={() => changeLanguage('nl') } className={classes.flag} />
            </div>
          </Toolbar>
          
        </AppBar>
        <main className={classes.layout}>
          <Paper className={classes.paper}>

            <Typography component="h1" variant="h5" align="center">
              {t("Afspraak maken met ReisStudentArts Groningen")}
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper} >
              {
                steps.map((label) => (
                    <Step key={label} className={classes.nomobile}>
                      <StepLabel>{small_screen ? t(label) : ""}</StepLabel>
                    </Step>
                ))} 
            </Stepper>
            <React.Fragment>

              <React.Fragment>
                {<div>
                {activeStep < 2 && 
                            <Typography variant="h6" gutterBottom >
                            {t('Stap')} {activeStep + 1}: {t(steps[activeStep])}
                        </Typography>
                }
                  </div>}
                {getStepContent(activeStep)}
              </React.Fragment>

            </React.Fragment>
          </Paper>
          <Copyright />
        </main>
      </React.Fragment>
  );
}