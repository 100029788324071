import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {connect} from "react-redux";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation, withTranslation, Trans } from "react-i18next";


function jsUcfirst(string)
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}




function Review({formData, Next, classes, Back}) {
    console.log(formData);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { t, i18n } = useTranslation();
    const [submitted, set_submitted] = useState(false);
    const submitForm = () => {
        set_submitted(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };
        
        let address;
        if (window.location.hostname == 'localhost'){
            address = 'http://127.0.0.1:8000/';
        }else{
            address = 'https://reisstudentarts.nl/';
        }
        
        fetch(`${address}api/aanvraag/`, requestOptions)
            .then(response => response.json())
            .then(data => {
                Next()
            });
    }

    const review_table = Object.keys(formData).map((key) => (
        <TableRow key={key}>
            <TableCell component="th" scope="row">
                <Box color="text.disabled">
                    {jsUcfirst(t(key).replace("_", " ")) + ':'}
                </Box>
            </TableCell>
            <TableCell align="right">{t(formData[key])}</TableCell>
        </TableRow>
    ));

    const submit_loading = () => {
        if (submitted){
            return(
                <Box ml={7} mt={1}>{t("Versturen")}... <CircularProgress/></Box>
            )
        }else{
            return(<Button
                onClick={() => {submitForm()}}
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
            >
                Submit
            </Button>)
        }
    }

    return (
        <React.Fragment>
            <Grid container justify='center'>
                <Grid item xs={12} >
                    <Box mt={2}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableBody>
                                    {review_table}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>
            </Grid>
            <div className={classes.buttons}>

                <Button type="submit" onClick={() => {Back()}} className={classes.button}>
                    back
                </Button>

                {submit_loading()}
            </div>
        </React.Fragment>
    );
}

function mapStateToProps(state) {
    return {formData: state.formData};
}

export default connect(mapStateToProps)(Review);