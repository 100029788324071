import React, {useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Controller, useForm} from "react-hook-form";
import {addFormData} from "../redux/actions";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {Box} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import nlLocale from "date-fns/locale/nl";
import Vaccinaties from "./Vaccinaties"
import { useTranslation, withTranslation, Trans } from "react-i18next";


const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


const ReisForm = ({dispatch, formData, Next, classes, Back}) => {
    const classes2 = useStyles();
    const [is_gezondheid_problemen, set_gezondheid_problemen] = useState(formData.risicogebied_geweest);
    const {register, handleSubmit, watch, errors, control} = useForm({
        defaultValues: formData
    });
    const { t, i18n } = useTranslation();
    let buttonClicked;



    const onSubmit = (data) => {
        if (document.activeElement.id !== 'vaccinaties' && document.activeElement.getAttribute("aria-label") !== 'Jaar') {
            dispatch(addFormData(data));
            if (buttonClicked === "Back") {
                Back();
            } else {
                Next();
            }
        }
    };





    const setGezondHeidsProblem = (event) => {
        set_gezondheid_problemen(event.target.value);
    }

    const setGezondHeidsProblemen = () => {
        if (is_gezondheid_problemen === "Ja"){

            return (
                <Grid item xs={12} md={12}>
                    <TextField required inputRef={register} name="gezondheidsproblemen"
                               {...(formData.gezondheidsproblemen === "Nee" ? {defaultValue: ""} : {})}
                               id="gezondheidsproblemen" label="Wat voor gezondheidsproblemen?" fullWidth/>
                </Grid>
            )}
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>




                    <Grid item xs={12}>
                        <Box mt={1} mb={-3}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend"> {t("Heeft u toen problemen met betrekking tot uw gezondheid gehad?")}</FormLabel>
                                <RadioGroup defaultValue={formData.gezondheidsproblemen_gehad}
                                            name="gezondheidsproblemen_gehad" onChange={setGezondHeidsProblem} row>
                                    <FormControlLabel value="Ja" control={<Radio required inputRef={register} />} label={t("Ja")} />
                                    <FormControlLabel value="Nee" control={<Radio required inputRef={register} />} label={t("Nee")} />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Grid>
                    {setGezondHeidsProblemen()}


                    <Grid item xs={12} sm={12}>
                        <TextField
                            inputRef={register}
                            required
                            id="chronische_aandoeningen"
                            name="chronische_aandoeningen"
                            label={t("Heeft u chronische aandoeningen? Zo ja welke?")}
                            fullWidth
                            autoComplete="initials"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            inputRef={register}
                            required
                            id="medicatie"
                            name="medicatie"
                            label={t("Gebruikt u medicatie? Zo ja welke?")}
                            fullWidth
                            autoComplete="initials"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            inputRef={register}
                            required
                            id="landen"
                            name="landen"
                            label={t("Welke landen gaat u bezoeken?")}
                            fullWidth
                            autoComplete="initials"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            inputRef={register}
                            required
                            id="gebieden"
                            name="gebieden"
                            label={t("Welke gebieden gaat u bezoeken?")}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>


                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nlLocale}>
                            <Controller
                                name="vertekdatum"
                                control={control}
                                render={({ onChange, ref, ...rest }) => (
                                    <KeyboardDatePicker
                                        required
                                        margin="normal"
                                        id="vertekdatum"
                                        label={t("Vertrekdatum")+ " (dd/mm/yyyy)"}
                                        format="dd/MM/yyyy"
                                        {...rest}
                                        onChange={(e) => {
                                            if (e !== null) {
                                                onChange(e);
                                            }
                                        }
                                        }
                                    />
                                )}
                            />
                        </MuiPickersUtilsProvider>


                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nlLocale}>
                            <Controller
                                name="retourdatum"
                                control={control}
                                render={({ onChange, ref, ...rest }) => (
                                    <KeyboardDatePicker
                                        required
                                        margin="normal"
                                        id="retourdatum"
                                        label={t("Retourdatum") + " (dd/mm/yyyy)"}
                                        format="dd/MM/yyyy"
                                        {...rest}
                                        onChange={(e) => {
                                            if (e !== null) {
                                                onChange(e);
                                            }
                                        }
                                        }
                                    />
                                )}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>


                    <Grid item xs={12}>
                        <FormControl className={classes2.formControl}>
                            <InputLabel htmlFor="age-native-simple">{t("Wat voor reis?")}</InputLabel>
                            <Select
                                inputRef={register}
                                name="reis"
                                id="reis"
                                native
                            >
                                <option value={"Backpacking"}>{("Backpacking")}</option>
                                <option value={"All-inclusive"}>{("All-inclusive")}</option>
                                <option value={"Primitief"}>{t("Primitief")}</option>
                                <option value={"Rondreis"}>{t("Rondreis")}</option>
                                <option value={"Georganiseerd"}>{t("Georganiseerd")}</option>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Vaccinaties />
                    </Grid>


                </Grid>
                <div className={classes.buttons}>

                    <Button type="submit" onClick={() => {buttonClicked = "Back"}} className={classes.button}>
                        back
                    </Button>

                    <Button
                        onClick={() => {buttonClicked = "Next"}}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                    >
                        Next
                    </Button>
                </div>
            </form>
        </React.Fragment>
    );
}

function mapStateToProps(state) {
    return {formData: state.formData};
}

export default connect(mapStateToProps)(ReisForm);