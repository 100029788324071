let nextTodoId = 0;

export const addTodo = content => ({
  type: "ADD_TODO",
  payload: {
    id: ++nextTodoId,
    content
  }
});

export const toggleTodo = id => ({
  type: "TOGGLE_TODO",
  payload: { id }
});

export const remText = () => ({
  type: "NO_TEXT"
})

export const addXText = () => ({
  type: "ADD_X"
})

export const setFilter = filter => ({ type: "SET_FILTER", payload: { filter } });


export const addFormData = formData => ({
  type: "formData",
  payload: {formData}
})