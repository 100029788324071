const initialState = {
    text: "",
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "NO_TEXT": {
        return {
            text: "",
        };
    }
    case "ADD_X": {
      return {
            text: state.text + "x",
      };
    }
    default:
      return state;
  }
}
