let initialState =  {}
if (new URLSearchParams(window.location.search).has('voorbeeld')){
    initialState = JSON.parse('{"eerdere_vaccinaties":"HPV(2001), Gele koorts(2000), HPV(1999), Tetanus (1970)","chronische_aandoeningen":"Arm klachten","geslacht":"Man","email": "ludgerlef@hotmail.com", "voorletters":"LEF","achternaam":"Visser","geboorte_datum":"01 September 1997","adres":"Gelkingestraat 1","postcode":"9999 TT","plaats":"Groningen","telefoon":"0612345678","verzekeraar":"CZ","verzekeringsnummer":"12345","risicogebied_geweest":"Ja","risicogebied":"Vietnam","gezondheidsproblemen_gehad":"Ja","gezondheidsproblemen":"Astma","landen":"Vietnam","gebieden":"Noord Vietnam","medicatie":"Paracetamol","vertekdatum":"01 September 2020","retourdatum":"19 september 2020","reis":"Backpacking"}')
}


export default function(state = initialState, action) {
  switch (action.type) {
    case "formData": {
        state = {...state, ...action.payload.formData}
        return state;
    }
    default:
      return state;
  }
}
