import React from 'react';
import { Box, FormControl, FormLabel, Radio, RadioGroup, Button, Checkbox, TextField, Typography, Grid, FormControlLabel } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import { addFormData } from "../redux/actions";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import nlLocale from "date-fns/locale/nl";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import flag_eng from "./../images/flag_eng.png"
import flag_nl from "./../images/flag_nl.png"
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({

}));


const AddressForm = ({ dispatch, formData, Next, classes }) => {

    const { t, i18n } = useTranslation();
    const { register, handleSubmit, watch, errors, setValue, control } = useForm({
        defaultValues: formData
    });

    const onSubmit = (data, event) => {
        dispatch(addFormData(data));
        Next();
    };

    const verzekeraars = [
        "ASR",
        "AZVZ",
        "Aegon",
        "Aevitae",
        "Anderzorg",
        "Avéro Achmea",
        "Besured",
        "Bewuzt",
        "C4Me",
        "CZ",
        "CZdirect",
        "DSW",
        "De Amersfoortse",
        "De Friesland",
        "De Goudse",
        "Delta Lloyd",
        "Ditzo",
        "FBTO",
        "HEMA",
        "IAK",
        "IZA",
        "IZZ",
        "Interpolis",
        "Jaaah",
        "Just",
        "Kruidvat",
        "Lancyr",
        "Menzis",
        "National Academic",
        "Nationale-Nederlanden",
        "Nedasco",
        "OHRA",
        "ONVZ",
        "OZF",
        "PMA",
        "PNOzorg",
        "Postcode Zorgcollectief",
        "Pro Life",
        "Promovendum",
        "SNS",
        "Salland",
        "Stad Holland",
        "Studenten Goed Verzekerd",
        "Turien & Co.",
        "UMC",
        "United Insurance",
        "UnitedConsumers",
        "Univé",
        "VGZ",
        "Voogd & Voogd",
        "VvAA",
        "YouCare",
        "ZEKUR",
        "ZieZo",
        "Zilveren Kruis",
        "Zorg en Zekerheid",
        "ZorgDirect",
        "inTwente"
    ];





    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>

                <Grid container spacing={1}>

                    <Grid item xs={12} sm={12}>
                        <Box mt={1} mb={-2}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t("Geslacht")}</FormLabel>
                                <RadioGroup defaultValue={formData.geslacht} aria-label="gender" name="geslacht" row>
                                    <FormControlLabel value="Man" control={<Radio required inputRef={register} />} label={t("Man")} />
                                    <FormControlLabel value="Vrouw" control={<Radio required inputRef={register} />} label={t("Vrouw")} />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            inputRef={register}
                            required
                            id="voorletters"
                            name="voorletters"
                            label={t("Voorletters")}
                            fullWidth
                            autoComplete="initials"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            inputRef={register}
                            required
                            id="achternaam"
                            name="achternaam"
                            label={t("Achternaam")}
                            fullWidth
                            autoComplete="family-name"
                        />
                    </Grid>
                    <Grid item xs={12}>

                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nlLocale}>
                            <Controller
                                defaultValue={null}
                                name="geboorte_datum"
                                control={control}
                                render={({ onChange, ref, ...rest }) => (
                                    <KeyboardDatePicker
                                        clearable
                                        required
                                        fullWidth
                                        margin="normal"
                                        id="geboorte_datum"
                                        label={t("Geboortedatum") + " (dd/mm/yyyy)"}
                                        format="dd/MM/yyyy"
                                        {...rest}
                                        onChange={(e) => {
                                            if (e !== null) {
                                                onChange(e);
                                            }
                                        }
                                        }
                                    />

                                )}
                            />
                        </MuiPickersUtilsProvider>


                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            inputRef={register}
                            required
                            id="adres"
                            name="adres"
                            label={t("Adres")}
                            fullWidth
                            autoComplete="street-address"
                        />

                    </Grid>



                    <Grid item xs={12}>
                        <TextField
                            inputRef={register}
                            required
                            id="postcode"
                            name="postcode"
                            label={t("Postcode")}
                            fullWidth
                            autoComplete="postcode"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            inputRef={register}
                            required
                            id="city"
                            name="plaats"
                            label={t("Plaats")}
                            fullWidth
                            autoComplete="shipping address-level2"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            inputRef={register}
                            required
                            id="telefoon"
                            name="telefoon"
                            label={t("Telefoon")}
                            fullWidth
                            autoComplete="tel-local"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            inputRef={register}
                            required
                            id="email"
                            name="email"
                            label={t("Email")}
                            fullWidth
                            autoComplete="email"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box fontWeight={600} mt={2}>
                            {t("Verzekeringsgegevens")}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>


                        <Controller
                            render={(props) => (
                                <Autocomplete
                                    {...props}
                                    options={verzekeraars}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            required={i18n.language == 'nl'}
                                            {...params}
                                            label={t("Verzekeraar")}
                                            fullWidth
                                        />
                                    )}
                                    onChange={(_, data) => props.onChange(data)}
                                />
                            )}
                            name="verzekeraar"
                            control={control}
                        />

                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            inputRef={register}
                            required={i18n.language == 'nl'}
                            id="verzekeringsnummer"
                            name="verzekeringsnummer"
                            label={t("Verzekeringsnummer")}
                            InputProps={{
                                inputProps: {
                                    maxLength: 9,
                                    minLength: 9
                                }
                            }}
                            fullWidth
                        />
                    </Grid>

                </Grid>
                <div className={classes.buttons}>

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                    >
                        Next
                    </Button>
                </div>
            </form>

        </React.Fragment>
    );
}

function mapStateToProps(state) {
    return { formData: state.formData };
}

export default connect(mapStateToProps)(AddressForm);
