import React, {useState, useEffect} from "react";
import Table, { MTableToolbar, MTableBody} from "material-table";
import {TextField} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import {connect} from "react-redux";
import {addFormData} from "../redux/actions";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Paper } from '@material-ui/core'
import { useTranslation, withTranslation, Trans } from "react-i18next";
import { Typography } from "@material-ui/core";


function Vaccinaties ({dispatch, formData, Next, classes, vaccinaties}){
    const [data, setData] = useState(vaccinaties);

    useEffect(() => toRedux(), [data]);

    const toRedux = () => {
        const test2 = data.map(item => `${item.naam}(${item.jaar})`).join(",");
        const test = {eerdere_vaccinaties: test2};
        dispatch(addFormData(test));
    }
    
    const { t, i18n } = useTranslation();
    const vaccinaties_lijst = ['Dktp-Hib-hepb','Hep b','Hep a','Tetanus ','Bmr','Meningococcen(MenACWY)','Dtp','HPV',('Pneumococcen'),t('Gele koorts'),'Dyfterie','Rabies',t('Typhus')];
    return (


        <div>
            <Typography style={{marginTop: "10px", fontWeight: "500"}} variant="p"> Voeg hieronder met de + knop alle vaccinaties toe die u eerder heeft gehad:</Typography>
            <div style={{ maxWidth: "100%", paddingTop: "12px" }}>
                <Table
                    components={{
                        Container: props=> <React.Fragment><Paper {...props} elevation={0}/></React.Fragment>,
                    }}
                    localization={{
                        body: {
                            editRow:{
                                deleteText: t("Deze vaccinatie verwijderen?")
                            },
                            emptyDataSourceMessage: t('Nog geen vaccinaties gehad')
                        },
                        header : {
                            actions: ''
                        }
                    }}
                    options={{
                        paging: false,
                        search: false,
                        showTitle: false,
                    }}
                    columns={[
                        {
                            title: t("Eerdere vaccinatie(s)"),
                            field: "naam",
                            editComponent: editProps => (
                                <Autocomplete
                                    openOnFocus
                                    id="vaccinaties"
                                    options={vaccinaties_lijst}
                                    getOptionLabel={(option) => option}
                                    onChange={(_,value) => {
                                        editProps.onChange(value)
                                    }}
                                    renderInput={(params) => <TextField autoFocus {...params} label={t("Vaccinatie")} />}
                                />
                            )
                        },
                        { title: t("Jaar"), field: "jaar", type: "numeric"}
                    ]}
                    data={data}
                    // onChange={() => console.log('test')}
                    title="Vaccinaties"
                    icons={{
                        Add: props => <AddIcon />,
                        Edit: props => <EditIcon />,
                        Delete: props => <DeleteIcon />,
                        Clear: props => <DeleteIcon />,
                        Check: props => <CheckIcon />,
                        ResetSearch: props => <DeleteIcon />
                    }}
                    editable={{
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    setData([...data, newData]);
                                    resolve();
                                }, 100);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const dataUpdate = [...data];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData;
                                    setData([...dataUpdate]);
                                    resolve();
                                }, 100);
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const dataDelete = [...data];
                                    const index = oldData.tableData.id;
                                    dataDelete.splice(index, 1);
                                    setData([...dataDelete]);
                                    resolve();
                                }, 100);
                            })
                    }}
                />
            </div>
        </div>

    );
}

function mapStateToProps(state) {
    let state_vaccinaties;
    if (state.formData.eerdere_vaccinaties == null || state.formData.eerdere_vaccinaties === ''){
        state_vaccinaties = [];
    }else {
        state_vaccinaties = state.formData.eerdere_vaccinaties.split(',').map(item => ({
            naam: item.split('(')[0],
            jaar: item.split('(')[1].replace(')', '')
        }));
    }
    return {vaccinaties: state_vaccinaties};
}

export default connect(mapStateToProps)(Vaccinaties);